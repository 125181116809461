import React, { useState } from 'react';
import { Table, Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './SzemelyList.css'; // Külön CSS a hover hatás kezelésére
import DeleteSzemely from './deleteSzemely';

function SzemelyList({ szemelyek }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const navigateToDetails = (szemelyId) => {
    navigate(`/szemely/${szemelyId}`);
  };

  return (
    <>
      {szemelyek && (
        <Table striped bordered hover className="szemely-list mt-4">
          <thead>
            <tr>
              <th>Név</th>
              <th>Részletek</th>
              <th>Műveletek</th>
            </tr>
          </thead>
          <tbody>
            {szemelyek.map((szemely, index) => (
              <tr key={szemely.id}>
                <td
                  style={{ cursor: 'pointer', color: 'var(--bs-primary)' }}
                  onClick={() => navigateToDetails(szemely.id)}
                >
                  {szemely.full_name}
                </td>
                <td>
                  <Button
                    variant="link"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    className="text-decoration-none"
                  >
                    Részletek
                  </Button>
                  {hoveredIndex === index && (
                    <div className="hover-popup">
                      <p><strong>Közismert:</strong> {szemely.kozismert ? 'Igen' : 'Nem'}</p>
                      <p><strong>Láthatóság: </strong><Badge pill variant={szemely.global_visibility ? 'success' : 'warning'}>
                        {szemely.global_visibility == 'public' ? 'Publikus' :
                        szemely.global_visibility == 'anonym' ? 'Anonim':
                        szemely.global_visibility == 'csoportfuggo' ? 'Csoportfüggő':
                        szemely.global_visibility == 'anonym' ? 'Privát': 'error'}
                      </Badge></p>
                      <p><strong>Feltöltő:</strong> @{szemely.feltolto}</p>
                      <p><strong>Ismertető:</strong>{szemely.ismerteto}</p>      
                    </div>
                  )}
                  </td>
                  <td>
                  <DeleteSzemely szemely={szemely}/>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default SzemelyList;