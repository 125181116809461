import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useFetching } from "../auth/apicalls";
import { Loading } from "../alert/Loading";
import { ErrorAlert } from "../alert/ErrorAlert";
import AOSzempontok from './AOSzempontok';
import UploadButton from './UploadPictureButton';
import SzemelyKepek from './SzemelyKepek';
import SzemelyReszletek from './SzemelyReszletek';

function SzemelyDetail() {
  const { id: szemelyId } = useParams();

  const { data: szemelyData, isError, isLoading, error } = useFetching(`/api/szemely/${szemelyId}/`);
  const { data: groupedAOSzempontData,
    isError: isAOSzempontError,
    isLoading: isAOSzempontLoading } = useFetching(`/api/aoszempont/?szemely=${szemelyId}`);

  const kepekRef = useRef(null); // Ref a Képek szakaszhoz

  const scrollToKepek = () => {
    if (kepekRef.current) {
      kepekRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="szemely-detail my-4">
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text={error?.response?.data?.user_error || "Ismeretlen hiba történt. Nem lehet a személyt betölteni #U100"} />}
      {szemelyData && (
        <div className="szemely-card">
          <div className="row">
            <div className="col-6">
              <SzemelyReszletek {...{ szemelyData }} />
            </div>
            <div className="col-3">
              <UploadButton szemelyId={szemelyId} />
            </div>
          </div>
          <div ref={kepekRef}>
            <SzemelyKepek kepek={szemelyData.szemely_kepek} szemelyId={szemelyId} />
          </div>
          <React.Fragment>
            {groupedAOSzempontData && Object.keys(groupedAOSzempontData).length === 0 ? (
          <div>Be kell lépned egy arcolvasás csoportba ahhoz, hogy elemezhesd az arcot!
            <br />
            Ehhez javaslom, hogy írj a mindenegyseg@gmail.com címre, és kérj belépést egy csoportba.
          </div>
        ) : (
          <div className="aoszempont-section pt-2">
            {isAOSzempontLoading && <Loading size={40} />}
            {isAOSzempontError && <ErrorAlert show={isAOSzempontError} text="Could not load AO szempontok" />}
            {groupedAOSzempontData && <AOSzempontok groupedData={groupedAOSzempontData} szemelyId={szemelyId} scrollToKepek={scrollToKepek} />}
          </div>
        )}
          </React.Fragment>
        </div>
      )}
    </div>
  );
}

export default SzemelyDetail;
