import React, { useRef, useState, useEffect } from 'react';
import RatingScale from './RatingScale';
import { usePost } from '../auth/apicalls';
import { useQueryClient } from 'react-query';
import './AOSzempontok.css'; // CSS fájl importálása

function AOSzempontok({ groupedData, szemelyId, scrollToKepek }) {
  const queryClient = useQueryClient();
  const scrollContainerRef = useRef(null);
  const categoryButtonRefs = useRef({});
  const [activeIndex, setActiveIndex] = useState(0);

  const categories = Object.keys(groupedData);
  const flatData = categories.flatMap((arcResz) =>
    groupedData[arcResz].map((aoszempont) => ({
      ...aoszempont,
      category: arcResz,
    }))
  );

  const { mutate: postRating } = usePost(`/api/szemely/${szemelyId}/?request_type=add-aoszempont-ertekeles`, {
    onSuccess: () => {
      queryClient.invalidateQueries(`/api/aoszempont/?szemely=${szemelyId}`);
    },
    onError: (error) => {
      console.error('Error submitting rating:', error.response?.data?.user_error || error.message);
    },
  });

  const handleRatingSelect = (aoszempont, ertekeles, side, status = 'helyesnek_tartja') => {
    postRating({ aoszempont, status, ertekeles, oldal: side });
    const nextIndex = Math.min(activeIndex + 1, flatData.length - 1);
    // setActiveIndex(nextIndex);
    scrollToKepek();
  };

  const scrollToCategory = (category) => {
    const firstIndex = flatData.findIndex((item) => item.category === category);
    if (firstIndex >= 0) {
      setActiveIndex(firstIndex);
      scrollToKepek();
    }
  };

  const handleArrowClick = (direction) => {
    const newIndex = activeIndex + direction;
    if (newIndex >= 0 && newIndex < flatData.length) {
      setActiveIndex(newIndex);
      scrollToKepek();
    }
  };

  const handleSkipToUnrated = () => {
    const nextUnratedIndex = flatData.findIndex(
      (item, index) =>
        index > activeIndex &&
        (item.erzelmi_oldal_ertekeles === null || item.logikai_oldal_ertekeles === null)
    );
    if (nextUnratedIndex !== -1) {
      setActiveIndex(nextUnratedIndex);
      scrollToKepek();
    }
  };

  useEffect(() => {
    const activeCategory = flatData[activeIndex].category;
    const categoryButton = categoryButtonRefs.current[activeCategory];
    if (categoryButton) {
      categoryButton.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [activeIndex]);
  
  return (
    <div className="aoszempont-container">
      <div className="category-navigation">
        {categories.map((category) => (
          <button
            key={category}
            ref={(el) => (categoryButtonRefs.current[category] = el)}
            className={`category-button ${flatData[activeIndex].category === category ? 'active' : ''}`}
            onClick={() => scrollToCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="navigation-controls">
        <button className="arrow left-arrow btn rounded" onClick={() => handleArrowClick(-1)} disabled={activeIndex === 0}>
          ◀
        </button>
        <span className="card-index">
          {activeIndex + 1} / {flatData.length}
        </span>
        <button className="arrow right-arrow btn rounded" onClick={() => handleArrowClick(1)} disabled={activeIndex === flatData.length - 1}>
          ▶
        </button>
        <button className="arrow double-arrow btn rounded" onClick={handleSkipToUnrated} disabled={activeIndex === flatData.length - 1}>
        <svg width="16" height="16" class="bi bi-skip-forward-fill">
          <path d="M15.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8.753l-6.267 3.636c-.54.313-1.233-.066-1.233-.697v-2.94l-6.267 3.636C.693 12.703 0 12.324 0 11.693V4.308c0-.63.693-1.01 1.233-.696L7.5 7.248v-2.94c0-.63.693-1.01 1.233-.696L15 7.248V4a.5.5 0 0 1 .5-.5"/>
        </svg>
        </button>
      </div>

      <div className="aoszempont-carousel" ref={scrollContainerRef}>
        {flatData.map((aoszempont, index) => (
          <div
            key={aoszempont.id}
            className={`aoszempont-kartya ${index === activeIndex ? 'active' : ''}`}
            style={{ transform: `translateX(${-activeIndex * 100}%)` }}
          >
            <RatingScale
              aoszempont={aoszempont}
              onRatingSelect={(rating, side) => handleRatingSelect(aoszempont.id, rating, side)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AOSzempontok;

