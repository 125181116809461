// Szemely.jsx
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useFetching } from "../auth/apicalls";
import { Loading } from "../alert/Loading";
import { ErrorAlert } from "../alert/ErrorAlert";
import SzemelyList from './SzemelyList';
import SzemelyForm from './SzemelyForm';

function Szemely() {
  const { data: szemelyData, isError, isLoading, refetch, error } = useFetching('/api/szemely/');
  const [showModal, setShowModal] = useState(false);
  
  return (
    <>
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text={error?.response?.data?.user_error || "Ismeretlen hiba történt."}/>}
      {szemelyData && (
        <div className="mt-4">
          <h2 className="mt-3 mb-5 text-center">Személyek listája</h2>
            <Button className="btn btn-success" onClick={() => setShowModal(true)}>
              Új személy létrehozása
            </Button>
          <SzemelyList szemelyek={szemelyData} />

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Új személy létrehozása</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SzemelyForm
                onSuccess={() => {
                  refetch();
                }}
                onClose={() => setShowModal(false)}
              />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}

export default Szemely;
