import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { usePost } from '../auth/apicalls';

function SzemelyForm({ onSuccess, onClose }) {
  const [formValues, setFormValues] = useState({
    full_name: '',
    owner: false,
    global_visibility: 'public',
    ismerteto: '',
    kozismert: false,
  });

  const { mutate: postSzemely, isLoading, isError, error } = usePost('/api/szemely/', {
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error('Hiba a személy létrehozásakor:', error.response?.data?.user_error || error.message);
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postSzemely(formValues);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Név</Form.Label>
        <Form.Control
          type="text"
          name="full_name"
          value={formValues.full_name}
          onChange={handleChange}
          placeholder="Adja meg a nevet"
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Ismertető</Form.Label>
        <Form.Control
          as="textarea"
          name="ismerteto"
          value={formValues.ismerteto}
          onChange={handleChange}
          placeholder="Rövid leírás a személyről"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Közismert"
          name="kozismert"
          checked={formValues.kozismert}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Láthatóság</Form.Label>
        <Form.Select
          name="global_visibility"
          value={formValues.global_visibility}
          onChange={handleChange}
        >
          <option value="public">Publikus: Minden regisztrált felhasználó láthatja</option>
          {/* <option value="iranyzatfuggo">Irányzatfüggő: Ha valaki benne van egy irányzatban, amiben te is benne vagy, annak llesz látható</option> */}
          <option value="csoportfuggo">Csoportfüggő: Válaszd ki mely csoportokkal szeretnéd megosztani</option>
          <option value="anonym">Anonim: Név nélkül fog megjelenni másoknak, viszont mindenki láthatja minden adatát </option>
          <option value="private">Privát: Csak a tulajdonos láthatja. Később még megosztható</option>
        </Form.Select>
      </Form.Group>

      {isError && <div className="text-danger">Hiba: {error?.response?.data?.user_error || 'Ismeretlen hiba'}</div>}

      <Button variant="primary" type="submit" disabled={isLoading}>
        {isLoading ? 'Feltöltés...' : 'Létrehozás'}
      </Button>
    </Form>
  );
}

export default SzemelyForm;
