import React, { useState } from "react";
import { usePost } from "../auth/apicalls";
import { Modal, Button, Form } from "react-bootstrap";

function AddUserModal({ show, handleClose, iranyzatId, notUsers, onUserAdded }) {
  const [selectedUserId, setSelectedUserId] = useState("");

  const { mutate: addUser, isLoading } = usePost(`/api/iranyzat/${iranyzatId}/`, {
    onSuccess: () => {
      onUserAdded(); // Frissíti a listát
      handleClose(); // Bezárja a modált
    },
    onError: (error) => {
      alert(`Hiba történt: ${error.response?.data?.user_error || "Ismeretlen hiba"}`);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedUserId) return;
    addUser({ user_id: selectedUserId });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Felhasználó hozzáadása</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Válassz felhasználót</Form.Label>
            <Form.Select value={selectedUserId} onChange={(e) => setSelectedUserId(e.target.value)}>
              <option value="">-- Válassz felhasználót --</option>
              {notUsers.map((user) => (
                <option key={user.id} value={user.nev}>
                  {user.nev}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <div className="mt-3 text-end">
            <Button variant="secondary" onClick={handleClose} className="me-2">
              Mégse
            </Button>
            <Button type="submit" variant="primary" disabled={isLoading || !selectedUserId}>
              {isLoading ? "Hozzáadás..." : "Hozzáadás"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUserModal;
