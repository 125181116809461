import React, { useState } from 'react';
import { Modal, Button, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from "../userkezeles/AuthContext";
import { useFetching } from "../auth/apicalls";
import { Loading } from "../alert/Loading";
import { ErrorAlert } from "../alert/ErrorAlert";
import CreateIranyzatModel from './CreateIranyzatModel';
import IranyzatList from './IranyzatList';

function Iranyzat() {
  const { isAdmin } = useContext(AuthContext);
  const { data: iranyzatData, isError, isLoading } = useFetching('/api/iranyzat/');
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text="" />}
      {iranyzatData && (
        <div className="mt-4">
          <h2 className="mt-3 mb-5 text-center">Spirituális Irányzatok listája</h2>
          {isAdmin && (
            <>
              <Button className="btn btn-primary" onClick={() => setShowModal(true)}>
                Új irányzat létrehozása
              </Button>
              <CreateIranyzatModel {...{ iranyzatData, showModal, setShowModal }} />
            </>
          )}

          <IranyzatList iranyzatok={iranyzatData.iranyzatok} />

        </div>
      )}
    </>
  );
}

export default Iranyzat;
