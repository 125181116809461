import React from "react";
import { Button } from "react-bootstrap";
import { useDelete } from "../auth/apicalls";
import "./SzemelyList.css";
import { showErrorAlert, showConfirmAlert } from "../alert/ErrorAlert";
import { useQueryClient } from "react-query";

function DeleteSzemely({ szemely }) {
  const queryClient = useQueryClient();
  const { mutate: deleteSzemely } = useDelete(`/api/szemely/${szemely.id}/`, {
    onSuccess: () => {
      queryClient.invalidateQueries("/api/szemely/");
    },
    onError: (error) => {
      showErrorAlert({
        title: "Nem sikerült törölni a megadott személyt!",
        text: error.response?.data?.user_error || "Ismeretlen hiba történt.",
      });
    },
  });

  const handleDelete = async () => {
    const confirmed = await showConfirmAlert({
      title: "Biztosan törölni szeretnéd ezt a személyt?",
      text: "Ez a művelet nem visszavonható!",
    });

    if (confirmed) {
      deleteSzemely();
    }
  };

  return (
    <>
      {szemely.can_delete && (
        <Button variant="danger" onClick={handleDelete} className="ms-2">
          Törlés
        </Button>
      )}
    </>
  );
}

export default DeleteSzemely;
