import React from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';
import { useFetching } from "../auth/apicalls";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';

const AdminLog = () => {
    const { data: logs, isError, isLoading } = useFetching('/api/log/');
    
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading logs.</div>;
    if (!logs) return <div>No logs available.</div>;

    // Napi statisztikák formázása
    const dailyStats = Object.entries(logs.napi_statisztikak).map(([date, count]) => ({ date, count }));
    
    // Felhasználónkénti interakciók
    const userStats = Object.entries(logs.felhasznalonkenti_interakciok).map(([user, count]) => ({ user, count }));

    // Óránkénti eloszlás
    const hourlyStats = Object.entries(logs.orankenti_eloszlas).map(([hour, count]) => ({ hour: `${hour}:00`, count }));
    
    // Napszaki eloszlás
    const timeOfDayStats = Object.entries(logs.napszaki_eloszlas).map(([label, count]) => ({ name: label, value: count }));
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    // Heti bontás
    const weeklyStats = Object.entries(logs.heti_bontas).map(([day, count]) => ({ day, count }));
    
    return (
        <div>
            <h2>Admin Logs</h2>

            <h3 className="mt-4">Felhasználónkénti interakciók</h3>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={userStats}>
                    <XAxis dataKey="user" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#007bff" />
                </BarChart>
            </ResponsiveContainer>

            <h3 className="mt-4">Óránkénti eloszlás</h3>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={hourlyStats}>
                    <XAxis dataKey="hour" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="count" stroke="#ff7300" />
                </LineChart>
            </ResponsiveContainer>

            <h3 className="mt-4">Napszaki eloszlás</h3>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                    <Pie data={timeOfDayStats} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100}>
                        {timeOfDayStats.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>

            <h3 className="mt-4">Heti bontás</h3>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={weeklyStats}>
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="#28a745" />
                </BarChart>
            </ResponsiveContainer>

            <h3 className="mt-4">Logok</h3>
            <ListGroup>
                <ListGroup.Item className="d-none d-md-block">
                    <Row className="fw-bold">
                        <Col md={3}>Profile</Col>
                        <Col md={3}>Feladat</Col>
                        <Col md={3}>Pontváltozás</Col>
                        <Col md={3}>Dátum</Col>
                    </Row>
                </ListGroup.Item>
                {logs.utolso_pontok.map((log, index) => (
                    <ListGroup.Item key={index}>
                        <Row>
                            <Col xs={12} md={3}>{log.profile}</Col>
                            <Col xs={12} md={3}>{log.feladat}</Col>
                            <Col xs={12} md={3}>{log.pontvaltozas}</Col>
                            <Col xs={12} md={3}>{new Date(log.datum).toLocaleString()}</Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default AdminLog;
