import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useFetching } from "../auth/apicalls";
import { Loading } from "../alert/Loading";
import { ErrorAlert } from "../alert/ErrorAlert";
import AddUserModal from "./AddUserModal";
import { Button } from "react-bootstrap";
import AuthContext from "../userkezeles/AuthContext";

function IranyzatDetail() {
  const { id } = useParams();
  const { data: iranyzatData, isError, isLoading, refetch } = useFetching(`/api/iranyzat/${id}/`);
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useContext(AuthContext);


  return (
    <div className="iranyzat-detail mt-4">
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text="Could not load iranyzat details" />}
      {iranyzatData && (
        <>
          <h2>{iranyzatData.iranyzat_tipus}</h2>
          <p><strong>Ágazat:</strong> {iranyzatData.agazat}</p>
          <p><strong>Leírás:</strong> {iranyzatData.leiras}</p>
          <p><strong>Láthatóság:</strong> {iranyzatData.public_lathatosag ? "Publikus" : "Privát"}</p>

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h3>Csoport tagok:</h3>
            {isAdmin && (
              <Button variant="success" onClick={() => setShowModal(true)} disabled={iranyzatData.not_users.length === 0}>
                + Felhasználó hozzáadása
              </Button>
            )}
          </div>

          {iranyzatData.users.length > 0 ? (
            <ul className="list-group mt-2">
              {iranyzatData.users.map((user) => (
                <li key={user.nev} className="list-group-item d-flex justify-content-between align-items-center">
                  <span>{user.nev}</span>
                  <span className="badge bg-primary">{user.jogosultsag}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="mt-3 text-muted">Nincsenek résztvevők ebben az irányzatban.</p>
          )}
        </>
      )}

      <AddUserModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        iranyzatId={id}
        notUsers={iranyzatData?.not_users || []}
        onUserAdded={refetch}
      />
    </div>
  );
}

export default IranyzatDetail;
